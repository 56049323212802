@charset "UTF-8";
/*$headings-margin-bottom: ($spacer / 2);*/
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");
@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:700");
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #e7e9ee;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: sans-serif;
  font-weight: 700;
  line-height: 1.1;
  color: #fff;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "â€”Â ";
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #fff;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

/*$headings-margin-bottom: ($spacer / 2);*/
/*$headings-margin-bottom: ($spacer / 2);*/
/**
* For collapsible content
*/
/*
	Partial -	Messages
*/
.message-container {
  margin: 10px 0;
  clear: both;
}
.message-container.empty {
  display: none;
}
.message-container .actionable-message {
  display: flex;
}
.message-container .actionable-message .message-content {
  flex: 1;
}
.message-container .actionable-message .message-actions {
  flex: 0;
  margin: 0 !important;
  text-align: inherit !important;
}
.message-container .actionable-message .message-actions .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0;
  font-size: 0.75rem;
}

div.message {
  position: relative;
  background-color: #dff0d8;
  border: 1px solid #31708f;
  color: #31708f;
  padding: 7px 7px 7px 1.75rem;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-align: left;
}
div.message:before {
  font-family: FontAwesome;
  content: "\f129";
  font-size: 18px;
  color: #31708f;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 7px;
  top: 8px;
}
div.message a {
  color: #31708f;
  margin-left: 2px;
  font-family: Open Sans Bold;
}
div.message a:hover {
  text-decoration: none;
}
div.message:before {
  top: 11px;
}
div.message + div.message {
  margin-top: 5px;
}
div.message.error {
  position: relative;
  background-color: #ebcccc;
  border: 1px solid #a94442;
  color: #a94442;
  padding: 7px 7px 7px 1.75rem;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-align: left;
}
div.message.error:before {
  font-family: FontAwesome;
  content: "\f071";
  font-size: 18px;
  color: #a94442;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 7px;
  top: 8px;
}
div.message.error a {
  color: #a94442;
  margin-left: 2px;
  font-family: Open Sans Bold;
}
div.message.error a:hover {
  text-decoration: none;
}
div.message.success {
  position: relative;
  background-color: #dff0d8;
  border: 1px solid #3c763d;
  color: #3c763d;
  padding: 7px 7px 7px 1.75rem;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-align: left;
}
div.message.success:before {
  font-family: FontAwesome;
  content: "\f00c";
  font-size: 18px;
  color: #3c763d;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 7px;
  top: 8px;
}
div.message.success a {
  color: #3c763d;
  margin-left: 2px;
  font-family: Open Sans Bold;
}
div.message.success a:hover {
  text-decoration: none;
}
div.message.important {
  position: relative;
  background-color: #faf2cc;
  border: 1px solid #8a6d3b;
  color: #8a6d3b;
  padding: 7px 7px 7px 1.75rem;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-align: left;
}
div.message.important:before {
  font-family: FontAwesome;
  content: "\f0ad";
  font-size: 18px;
  color: #8a6d3b;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 7px;
  top: 8px;
}
div.message.important a {
  color: #8a6d3b;
  margin-left: 2px;
  font-family: Open Sans Bold;
}
div.message.important a:hover {
  text-decoration: none;
}
div.message.warning {
  position: relative;
  background-color: #faf2cc;
  border: 1px solid #8a6d3b;
  color: #8a6d3b;
  padding: 7px 7px 7px 1.75rem;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-align: left;
}
div.message.warning:before {
  font-family: FontAwesome;
  content: "\f0ad";
  font-size: 18px;
  color: #8a6d3b;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 7px;
  top: 8px;
}
div.message.warning a {
  color: #8a6d3b;
  margin-left: 2px;
  font-family: Open Sans Bold;
}
div.message.warning a:hover {
  text-decoration: none;
}
div.message p:first-child {
  margin-top: 0;
}
div.message p:last-child {
  margin-bottom: 0;
}
div.message .miwt-collapsable-content {
  padding: 10px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.45);
  overflow-y: auto;
  max-height: 500px;
  z-index: 10000;
}

div.has-error, span.has-error {
  background-color: #ebcccc;
  border: 1px solid #a94442;
  padding: 5px !important;
}
div.has-error.profile-roles, span.has-error.profile-roles {
  margin-bottom: 1rem;
}
div.has-error.profile-roles .error-message, span.has-error.profile-roles .error-message {
  margin-top: 0.75rem;
}
div.has-error.profile-roles .pager, span.has-error.profile-roles .pager {
  margin-bottom: 0;
}
div.has-error .error-message, span.has-error .error-message {
  display: inline-block;
  padding-left: 1.5rem;
  color: #a94442;
  position: relative;
  font-size: 0.75rem;
}
div.has-error .error-message::before, span.has-error .error-message::before {
  content: "\f071";
  display: inline-block;
  font-family: FontAwesome;
  font-size: inherit;
  font-style: normal;
  font-weight: 400;
  left: 7px;
  line-height: 1;
  position: absolute;
  text-decoration: inherit;
  text-rendering: optimizelegibility;
  text-transform: none;
  top: 3px;
  vertical-align: middle;
  color: #a94442;
}

.picture-editor .message {
  padding: 5px 5px 5px 16px !important;
}

/*$headings-margin-bottom: ($spacer / 2);*/
#monitor .nav-monitoring,
#data .nav-scans,
#logs .nav-logs,
#config .nav-config {
  background: linear-gradient(to bottom, #385cad 0%, #5175c7 100%);
  border-top: 3px solid #ffc629 !important;
}
#monitor .nav-monitoring > .menuitemlabel,
#data .nav-scans > .menuitemlabel,
#logs .nav-logs > .menuitemlabel,
#config .nav-config > .menuitemlabel {
  text-decoration: none;
  color: #fff !important;
}

#header {
  background: #fff;
  padding: 0 0 0 1rem;
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: row wrap;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
#header > div {
  display: flex;
}
#header .logo-con {
  flex: 1 0 100%;
  justify-content: space-between;
  margin-right: 2rem;
  padding: 0.75rem 0;
}
#header .logo-con a {
  display: block;
}
#header .logo-con .fa.fa-bars {
  display: block;
  align-self: center;
  font-weight: 300;
  font-size: 1.5rem;
  cursor: pointer;
}
#header .nav-profile-con {
  flex: 2 1 auto;
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  height: calc(100vh - 60px);
  background: #fff;
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 99;
  width: 0;
  overflow: hidden;
}
.sidebar-open #header .nav-profile-con {
  width: auto;
  min-width: 50%;
}
#header div.menu {
  flex: 2;
  justify-content: center;
  font-family: "raleway", sans-serif;
  font-weight: 400;
  align-self: flex-end;
  width: 100%;
  height: 100%;
}
#header div.menu div.menuitemlabel {
  cursor: pointer;
}
#header div.menu .menu-t1 > li > .menuitemlabel {
  padding: 2rem 0;
}
#header div.menu ul.menubeanh li, #header div.menu ul.menubeanh-persistent li {
  float: none;
}
#header div.menu > ul.menu {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-flow: column nowrap;
  text-align: right;
  height: 100%;
}
#header div.menu > ul.menu > li {
  align-self: flex-end;
  width: 100%;
  height: 100%;
  border-top: 3px solid transparent;
  flex: 1 0 auto;
}
#header div.menu > ul.menu > li + li {
  margin-left: 0;
}
#header div.menu > ul.menu > li.mi-active, #header div.menu > ul.menu > li:hover {
  background: linear-gradient(to bottom, #385cad 0%, #5175c7 100%);
  border-top: 3px solid #ffc629;
}
#header div.menu > ul.menu > li.mi-active > .menuitemlabel, #header div.menu > ul.menu > li:hover > .menuitemlabel {
  text-decoration: none;
  color: #fff;
}
#header div.menu > ul.menu > li > .menuitemlabel {
  font-family: "Josefin Sans", sans-serif;
  text-transform: uppercase;
  padding: 2rem 0.5rem;
  display: flex;
  color: #385cad;
  flex: 1 0 auto;
  justify-content: center;
}
#header div.menu > ul.menu > li .menuitemlabel span::before {
  font-family: FontAwesome;
  font-size: 1.75rem;
  margin: -5px 8px 0 0;
  display: inline-block;
  vertical-align: middle;
  font-weight: 200;
}
#header div.menu > ul.menu > li ul {
  width: auto;
  border-right: 3px solid #ffc629;
  z-index: 999;
  display: block;
  position: relative;
}
#header div.menu > ul.menu > li ul li {
  line-height: 2;
}
#header div.menu > ul.menu > li ul li a {
  line-height: 1;
  padding: 0.75rem;
  display: block;
  color: #6d6e71;
}
#header div.menu > ul.menu > li ul li:hover a {
  background: #d6d9df;
}
#header div.menu > ul.menu > li ul.menu-t1 li.mi-active a {
  background: #d6d9df;
  color: #fff;
}
#header div.menu > ul.menu > li.nav-dashboard a.menuitemlabel span::before {
  content: "\f0e4";
}
#header div.menu > ul.menu > li.nav-monitoring > .menuitemlabel span::before {
  content: "\f06e";
}
#header div.menu > ul.menu > li.nav-scans > .menuitemlabel span::before {
  content: "\f02a";
}
#header div.menu > ul.menu > li.nav-config > .menuitemlabel span::before {
  content: "\f1de";
}
#header div.menu > ul.menu > li.nav-logs > .menuitemlabel span::before {
  content: "\f126";
}
#header div.menu > ul.menu > li.nav-help a.menuitemlabel span::before {
  content: "\f059";
}
#header div.menu > ul.menu > li.nav-welcome a.menuitemlabel span::before {
  content: "\f015";
}
#header div.menu > ul.menu > li.nav-contact-us a.menuitemlabel span::before {
  content: "\f0e0";
}
#header div.menu > ul.menu > li.nav-projects a.menuitemlabel span::before {
  content: "\f201";
}
#header div.menu > ul.menu > li.nav-user-management a.menuitemlabel span::before {
  content: "\f0c0";
}
#header div.menu > ul.menu > li.nav-support div.menuitemlabel span::before {
  content: "\f1cd";
}
#header div.menu > ul.menu a {
  color: #6d6e71;
  text-decoration: none;
}
#header div.logout {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  max-width: 8rem;
  font-size: 0.85rem;
}
#header div.logout > ul.menu {
  justify-content: flex-end;
}
#header div.logout > ul.menu > li.mi-parent {
  cursor: pointer;
  width: 100%;
}
#header div.logout > ul.menu > li.mi-parent > div.menuitemlabel {
  text-align: right;
  font-size: 0.75rem;
  padding: 0 0.75rem;
  width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  height: 100%;
  align-items: center;
}
#header div.logout > ul.menu > li.mi-parent > div.menuitemlabel:hover span {
  font-weight: 600;
}
#header div.logout > ul.menu > li.mi-parent:hover ul.menu-t2 {
  display: block;
  right: 0;
  top: 85px;
}

#header .location-selector {
  position: absolute;
  left: 1rem;
  font-size: 0.75rem;
  cursor: pointer;
  width: 9rem;
  top: 0.75rem;
}
#header .location-selector .toggler-wrapper {
  display: block;
  width: 100%;
  text-align: right;
}
#header .location-selector .toggler-wrapper .selected-location {
  position: relative;
  left: auto;
  top: auto;
}
#header .location-selector span.fa.fa-chevron-right {
  font-size: 0.5rem;
  margin-left: 0.25rem;
}
#header .location-selector.show .dropdown-menu {
  display: block;
}
#header .location-selector.show .dropdown-item {
  padding: 3px 10px;
  font-size: 0.75rem;
}

#header .selected-location {
  position: absolute;
  left: 1rem;
  top: 0.75rem;
  font-size: 0.75rem;
  width: 9rem;
  text-align: right;
}

@media (max-width: 768px) {
  .sidebar-open #header .nav-profile-con {
    width: auto;
    min-width: 30%;
  }
}
@media (min-width: 1024px) and (max-width: 1140px) {
  #header div.menu > ul.menu > li > .menuitemlabel {
    font-size: 0.9rem;
  }
  #header div.menu > ul.menu > li > .menuitemlabel > span::before {
    font-size: 1.25rem;
  }
}
@media (min-width: 1024px) {
  #header .logo-con {
    flex: 1 0 auto;
    justify-content: flex-start;
    max-width: 9rem;
    margin-right: 1rem;
  }
  #header .logo-con a {
    width: 100%;
  }
  #header .logo-con .fa.fa-bars {
    display: none;
  }
  #header .nav-profile-con {
    flex: 2 1 75%;
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    height: auto;
    min-width: 0;
    background: transparent;
    position: relative;
    top: auto;
    right: auto;
    overflow: visible;
    width: auto;
  }
  #header .nav-profile-con > div.menu:not(.logout) {
    padding: 0 0 0 1.5rem;
  }
  #header div.menu {
    align-self: center;
    width: auto;
  }
  #header div.menu > ul.menu {
    flex-flow: row nowrap;
    text-align: center;
    justify-content: space-between;
  }
  #header div.menu > ul.menu > li {
    width: auto;
  }
  #header div.menu > ul.menu > li > a.menuitemlabel {
    padding: 2rem 5px;
  }
  #header div.menu > ul.menu > li.mi-active > a, #header div.menu > ul.menu > li > a:hover {
    background: transparent;
    color: #6d6e71;
  }
  #header div.menu > ul.menu > li ul {
    width: 13rem;
    border-right: none;
    border-bottom: 3px solid #ffc629;
    background: #e7e9ee;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    display: none;
    position: absolute;
    text-align: left;
  }
  #header div.menu > ul.menu > li.over ul {
    display: block;
  }
  #header div.menu.logout > ul.menu {
    text-align: right;
    justify-content: flex-end;
  }
}
/*$headings-margin-bottom: ($spacer / 2);*/
/*--sidebar toggle---*/
#main-wrapper {
  min-height: calc(100vh - 68px);
  display: flex;
  flex-wrap: nowrap;
}
#main-wrapper main {
  flex: 1;
}

.sidebar-closed #side-nav,
#body-wrapper:not(.sidebar-open) #side-nav {
  width: 55px;
  overflow: hidden;
}
.sidebar-closed #side-nav .menu .mi a.menuitemlabel,
#body-wrapper:not(.sidebar-open) #side-nav .menu .mi a.menuitemlabel {
  padding: 8px 0 8px 8px;
}
.sidebar-closed #side-nav .menu span.mil,
#body-wrapper:not(.sidebar-open) #side-nav .menu span.mil {
  opacity: 0;
}

/*sidebar navigation*/
#side-nav {
  width: 210px;
  min-height: 100%;
  background: #2a3542;
}

.sidebar-open #main-content,
.sidebar-open #side-nav,
.sidebar-closed #main-content,
.sidebar-closed #side-nav {
  transition: all 0.5s ease;
}

.nav-collapse.collapse {
  display: inline;
}

#side-nav .menu {
  margin: 15px 0 0;
  padding: 0;
  list-style: none;
}

#side-nav .menu .mi {
  /*line-height: 20px !important;*/
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
  overflow: hidden;
  white-space: nowrap;
}

#side-nav .menu .mi span.mil {
  display: inline-block;
}

#side-nav .menu .mi a.menuitemlabel {
  color: #aeb2b7;
  text-decoration: none;
  display: block;
  padding: 15px 0 15px 10px;
  outline: none;
  transition: all 0.3s ease;
}

#side-nav .menu .mi a.menuitemlabel.mi-active,
#side-nav .menu .mi a.menuitemlabel:hover,
#side-nav .menu .mi a.menuitemlabel:focus {
  background: #35404d;
  color: #fff;
  display: block;
  border-radius: 4px;
}

#side-nav .menu .mi .menuitemlabel {
  padding-right: 6px;
}

#side-nav .menu .mi a.menuitemlabel:hover:before,
#side-nav .menu .mi a.menuitemlabel:focus:before,
#side-nav .menu .mi-active a.menuitemlabel:before {
  color: #EE695B;
}

#side-nav .menu .mi > .menuitemlabel:before {
  font-family: FontAwesome;
  padding-right: 4px;
  min-width: 22px;
  display: inline-block;
}

#side-nav .menu .my-quick-view > .menuitemlabel:before {
  content: "\f1ea";
}

#side-nav .menu .participant-mgt > .menuitemlabel:before {
  content: "\f19c";
}

#side-nav .menu .group-mgt > .menuitemlabel:before {
  content: "\f0c0";
}

#side-nav .menu .task-mgt > .menuitemlabel:before {
  content: "\f0ae";
}

#side-nav .menu .user-mgt > .menuitemlabel:before {
  content: "\f007";
}

#side-nav .menu .coached-mgt > .menuitemlabel:before {
  content: "\f0f7";
}

#side-nav .menu .activity-template-mgt > .menuitemlabel:before {
  content: "\f247";
}

#side-nav .menu .process-mgt > .menuitemlabel:before {
  content: "\f0ec";
}

#side-nav .menu .component-mgt > .menuitemlabel:before {
  content: "\f1c0";
}

#side-nav .menu .resource-mgt > .menuitemlabel:before {
  content: "\f03e";
}

/*$headings-margin-bottom: ($spacer / 2);*/
body div.miwt-ajax-progress {
  background-color: #fff;
  border-color: #259de2;
  color: #385cad;
}
body div.miwt-ajax-progress .label {
  color: inherit;
}
body div.miwt-ajax-progress progress {
  width: 100%;
  height: 1rem;
  margin: 0;
}

.miwt-ajax-progress .progress-con:not(.progress-indeterminate) progress {
  border: 0;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.miwt-ajax-progress .progress-con:not(.progress-indeterminate) progress::-webkit-progress-bar {
  background-color: #eee;
}
.miwt-ajax-progress .progress-con:not(.progress-indeterminate) progress::-moz-progress-bar {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
.miwt-ajax-progress .progress-con:not(.progress-indeterminate) progress::-webkit-progress-value {
  background-color: #0074d9;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.dnd-source {
  cursor: move;
  cursor: grab;
  cursor: -webkit-grab;
}

.dnd-active {
  cursor: no-drop;
}
.dnd-active .dnd-source {
  cursor: move;
  cursor: grabbing;
  cursor: -webkit-grabbing;
}
.dnd-active .dnd-target {
  cursor: copy;
}

/*$headings-margin-bottom: ($spacer / 2);*/
/*$headings-margin-bottom: ($spacer / 2);*/
/**
* Provide button look for MIWT MenuComponent
*/
.column-visibility ul li {
  margin: 0;
  font-size: 0.85rem;
}

.miwt-table {
  border: 1px solid #e7e7e7;
  width: 100%;
  border: none;
}
.miwt-table .column-visibility-selector {
  font-family: "open-sans", sans-serif;
  font-size: 14px;
  padding: 10px;
  border: 1px solid #aaa;
  top: 100%;
}
.miwt-table .column-visibility-selector ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.miwt-table .column-visibility-selector label {
  margin-left: 5px;
}
.miwt-table .column-visibility .btn.trigger {
  background-color: transparent;
  outline: none;
}
.miwt-table > thead > tr > th {
  background: #6d6e71;
  font-family: "raleway", sans-serif;
  font-weight: 800;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 2;
  padding: 5px 8px;
}
.miwt-table > thead > tr > th.action_column, .miwt-table > thead > tr > th.action-column {
  white-space: nowrap;
  width: 1px;
}
.miwt-table > thead > tr > th.control {
  width: 1px;
  white-space: nowrap;
}
.miwt-table > thead > tr > th.control .column-label {
  display: inline-block;
}
.miwt-table > thead > tr > th.control .column-label + .column-visibility {
  margin-left: 5px;
}
.miwt-table > thead > tr > th.control input[type=checkbox] {
  display: block;
}
.miwt-table > thead > tr > th.control .column-visibility input[type=checkbox] {
  display: inline-block;
}
.miwt-table > thead > tr > th button.configure {
  vertical-align: text-top;
}
.miwt-table > thead > tr > th a {
  color: #fff;
  text-decoration: none;
}
.miwt-table > thead > tr > th a:hover {
  text-decoration: underline;
}
.miwt-table > tbody > tr > td {
  font-family: "open-sans", sans-serif;
  font-size: 0.85rem;
  color: #343940;
  border: 2px solid #e7e9ee;
  padding: 5px 8px;
  transition: background-color 500ms;
  text-align: start;
}
.miwt-table > tbody > tr > td.type-date, .miwt-table > tbody > tr > td .type-listing-status {
  text-align: center;
}
.miwt-table > tbody > tr > td.action_column, .miwt-table > tbody > tr > td.action-column, .miwt-table > tbody > tr > td.actions-con {
  white-space: nowrap;
  width: 1px;
}
.miwt-table > tbody > tr > td.action_column.last-column, .miwt-table > tbody > tr > td.action-column.last-column, .miwt-table > tbody > tr > td.actions-con.last-column {
  text-align: right;
}
.miwt-table > tbody > tr > td.action_column .btn, .miwt-table > tbody > tr > td.action-column .btn, .miwt-table > tbody > tr > td.actions-con .btn {
  display: block;
}
.miwt-table > tbody > tr > td.action_column .btn + .btn, .miwt-table > tbody > tr > td.action-column .btn + .btn, .miwt-table > tbody > tr > td.actions-con .btn + .btn {
  margin-top: 0.5rem;
}
.miwt-table > tbody > tr > td.control {
  width: 1px;
  white-space: nowrap;
}
.miwt-table > tbody > tr > td.control input[type=checkbox] {
  display: block;
}
.miwt-table > tbody > tr > td input[type=checkbox] {
  vertical-align: middle;
}
.miwt-table > tbody > tr > td a {
  text-decoration: none;
}
.miwt-table > tbody > tr > td a:hover {
  text-decoration: underline;
}
.miwt-table > tbody > tr:nth-child(even) > td {
  background-color: #f7f7f7;
}
.miwt-table > tbody > tr.no_data > td {
  background-color: #f7f7f7;
  color: #aaa;
  font-style: italic;
}
.miwt-table > tbody > tr:hover > td {
  background-color: cornsilk;
}
.miwt-table caption {
  caption-side: top;
  text-align: left;
  margin-top: -2.5rem;
}

/* MIWT Trees. */
.miwt-tree {
  border: 1px solid #e7e7e7;
  width: 100%;
  border: 0 none;
}
.miwt-tree .column-visibility-selector {
  font-family: "open-sans", sans-serif;
  font-size: 14px;
  padding: 10px;
  border: 1px solid #aaa;
  top: 100%;
}
.miwt-tree .column-visibility-selector ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.miwt-tree .column-visibility-selector label {
  margin-left: 5px;
}
.miwt-tree .column-visibility .btn.trigger {
  background-color: transparent;
  outline: none;
}
.miwt-tree > thead > tr > th {
  background: #6d6e71;
  font-family: "raleway", sans-serif;
  font-weight: 800;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 2;
  padding: 5px 8px;
  border-bottom: 1px solid #e7e7e7;
}
.miwt-tree > thead > tr > th.action_column, .miwt-tree > thead > tr > th.action-column {
  white-space: nowrap;
  width: 1px;
}
.miwt-tree > thead > tr > th.control {
  width: 1px;
  white-space: nowrap;
}
.miwt-tree > thead > tr > th.control .column-label {
  display: inline-block;
}
.miwt-tree > thead > tr > th.control .column-label + .column-visibility {
  margin-left: 5px;
}
.miwt-tree > thead > tr > th.control input[type=checkbox] {
  display: block;
}
.miwt-tree > thead > tr > th.control .column-visibility input[type=checkbox] {
  display: inline-block;
}
.miwt-tree > thead > tr > th button.configure {
  vertical-align: text-top;
}
.miwt-tree > thead > tr > th a {
  color: #fff;
  text-decoration: none;
}
.miwt-tree > thead > tr > th a:hover {
  text-decoration: underline;
}
.miwt-tree > thead > tr > th.last-column {
  border-right: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
}
.miwt-tree > tbody > tr > td {
  font-family: "open-sans", sans-serif;
  font-size: 0.85rem;
  color: #343940;
  border: 2px solid #e7e9ee;
  padding: 5px 8px;
  transition: background-color 500ms;
  text-align: start;
}
.miwt-tree > tbody > tr > td.type-date, .miwt-tree > tbody > tr > td .type-listing-status {
  text-align: center;
}
.miwt-tree > tbody > tr > td.action_column, .miwt-tree > tbody > tr > td.action-column, .miwt-tree > tbody > tr > td.actions-con {
  white-space: nowrap;
  width: 1px;
}
.miwt-tree > tbody > tr > td.action_column.last-column, .miwt-tree > tbody > tr > td.action-column.last-column, .miwt-tree > tbody > tr > td.actions-con.last-column {
  text-align: right;
}
.miwt-tree > tbody > tr > td.action_column .btn, .miwt-tree > tbody > tr > td.action-column .btn, .miwt-tree > tbody > tr > td.actions-con .btn {
  display: block;
}
.miwt-tree > tbody > tr > td.action_column .btn + .btn, .miwt-tree > tbody > tr > td.action-column .btn + .btn, .miwt-tree > tbody > tr > td.actions-con .btn + .btn {
  margin-top: 0.5rem;
}
.miwt-tree > tbody > tr > td.control {
  width: 1px;
  white-space: nowrap;
}
.miwt-tree > tbody > tr > td.control input[type=checkbox] {
  display: block;
}
.miwt-tree > tbody > tr > td input[type=checkbox] {
  vertical-align: middle;
}
.miwt-tree > tbody > tr > td a {
  text-decoration: none;
}
.miwt-tree > tbody > tr > td a:hover {
  text-decoration: underline;
}
.miwt-tree > tbody > tr:nth-child(even) > td {
  background-color: #f7f7f7;
}
.miwt-tree > tbody > tr.no_data > td {
  background-color: #f7f7f7;
  color: #aaa;
  font-style: italic;
}
.miwt-tree > tbody > tr:hover > td {
  background-color: cornsilk;
}
.miwt-tree > tbody > tr > td {
  border-bottom: 1px solid #e7e7e7;
}
.miwt-tree > tbody > tr > td.leaf, .miwt-tree > tbody > tr > td.parent-sibling-following, .miwt-tree > tbody > tr > td.parent-last-sibling, .miwt-tree > tbody > tr > td.sibling-following, .miwt-tree > tbody > tr > td.last-sibling {
  background-color: transparent;
  border: 0 none;
}
.miwt-tree > tbody > tr > td.first-column {
  border-left: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
}
.miwt-tree > tbody > tr > td.last-column {
  border-right: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
}
.miwt-tree tr.dnd-source td.first-column {
  padding: 5px 8px;
  background-image: none;
}
.miwt-tree tr.dnd-source:hover td.first-column {
  background-image: none;
}
.miwt-tree .parent-sibling-following img.tpi,
.miwt-tree .last-sibling img.tpi,
.miwt-tree .sibling-following img.tpi {
  width: 14px;
}
.miwt-tree td.parent-sibling-following,
.miwt-tree td.sibling-following {
  background-image: url(/_resources/all/docroot/cms/icons/tree/vline.gif);
  background-repeat: no-repeat;
  background-position: 50% 0;
  border: 0 none;
  padding: 0;
}
.miwt-tree td.first {
  width: 1px;
}
.miwt-tree .last-sibling,
.miwt-tree td.sibling-following {
  width: 16px;
}
.miwt-tree td.parent-last-sibling {
  border: 0 none;
  background: transparent;
  padding: 0;
}
.miwt-tree td.sibling-following.leaf {
  background-image: url(/_resources/all/docroot/cms/icons/tree/tn.gif);
  background-repeat: no-repeat;
  background-position: 50% 0;
  border: 0 none;
  padding: 0;
}
.miwt-tree td.last-sibling {
  background-image: url(/_resources/all/docroot/cms/icons/tree/ln.gif);
  background-repeat: no-repeat;
  background-position: 50% 0;
  border: 0 none;
  padding: 0;
}
.miwt-tree div.column-visibility {
  padding-left: 0;
}

.miwt-config-option {
  text-align: left;
}

.miwt-config-option ul li {
  display: block;
}

.miwt-select-all-rows th,
.miwt-clear-row-selection th {
  text-align: center;
  background-color: #FFFFCC;
}

.sqlexception tbody.miwt-table-data,
.sqlexception .pager-bottom {
  display: none;
}

/*$headings-margin-bottom: ($spacer / 2);*/
div.window.dialog {
  padding: 0;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
}
div.window.dialog::before {
  content: "";
  background: rgba(98, 100, 102, 0.9);
  display: block;
  height: 100%;
  width: 100%;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
}
div.window.dialog .titlebar {
  background-color: #385cad;
  height: 70px;
  color: #fff;
}
div.window.dialog .titlebar_row td.windowbuttons {
  background: #d93636;
}
div.window.dialog .titlebar_row td.windowbuttons button.btn {
  color: transparent;
  overflow: hidden;
  white-space: nowrap;
  width: 54px;
  padding: 0 1rem;
}
div.window.dialog .titlebar_row td.windowbuttons button.btn::before {
  content: "\f00d";
  font-family: FontAwesome;
  color: #fff;
  font-size: 1.5rem;
}
div.window.dialog td.activewindowtitle {
  padding: 15px;
  border-bottom: 1px solid #dee2e6;
  text-align: left;
  margin: 0;
  line-height: 1.5;
  font-size: 1.25rem;
  font-weight: inherit;
  color: #fff;
  height: auto;
}
div.window.dialog td.windowbuttons {
  color: inherit;
  height: auto;
  border-bottom: 1px solid #dee2e6;
}
div.window.dialog td.windowbuttons .btn {
  background-color: transparent;
  padding: 0 10px 0 0;
  box-shadow: none;
}
div.window.dialog .windowcontent {
  top: 70px;
  padding: 15px;
  border: none;
  background-color: #e7e9ee;
  color: inherit;
  bottom: initial;
  left: initial;
  right: initial;
  width: 100%;
}
div.window.dialog .actions.bottom {
  width: 100%;
  text-align: right;
  padding: 1rem;
}
div.window.dialog .actions.bottom::after {
  display: block;
  clear: both;
  content: "";
}
div.window.dialog .actions.bottom .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}
div.window.dialog .actions.bottom .btn-group .btn + .btn {
  margin-left: -1px;
}
div.window.dialog .actions.bottom .btn-block + .btn-block {
  margin-left: 0;
}
div.window.dialog .btn.select {
  color: #fff;
  background-color: #259de2;
  border-color: #259de2;
  color: #fff;
  border-bottom: 3px solid #259de2;
  float: right;
}
div.window.dialog .btn.select:hover {
  color: #fff;
  background-color: #1a88c6;
  border-color: #1980bb;
}
div.window.dialog .btn.select:focus, div.window.dialog .btn.select.focus {
  color: #fff;
  background-color: #1a88c6;
  border-color: #1980bb;
  box-shadow: 0 0 0 0.2rem rgba(70, 172, 230, 0.5);
}
div.window.dialog .btn.select.disabled, div.window.dialog .btn.select:disabled {
  color: #fff;
  background-color: #259de2;
  border-color: #259de2;
}
div.window.dialog .btn.select:not(:disabled):not(.disabled):active, div.window.dialog .btn.select:not(:disabled):not(.disabled).active, .show > div.window.dialog .btn.select.dropdown-toggle {
  color: #fff;
  background-color: #1980bb;
  border-color: #1778b0;
}
div.window.dialog .btn.select:not(:disabled):not(.disabled):active:focus, div.window.dialog .btn.select:not(:disabled):not(.disabled).active:focus, .show > div.window.dialog .btn.select.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 172, 230, 0.5);
}
div.window.dialog.option-dialog {
  width: 100% !important;
  max-width: 40rem !important;
}
div.window.dialog.option-dialog .windowcontent {
  padding: 1.5rem;
}
div.window.dialog.option-dialog .option-dialog-message {
  margin: 1.25rem 0;
}
div.window.dialog.option-dialog .actions {
  text-align: right;
}

/*$headings-margin-bottom: ($spacer / 2);*/
.nav-actions {
  text-align: right;
  margin-bottom: -1.75rem;
}

.tab-container .tab-items {
  margin-bottom: -1px;
  padding-left: 0;
}
.tab-container .tab-items .ti {
  display: inline-block;
  vertical-align: bottom;
  border: 1px solid #ddd;
  margin-right: 5px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.tab-container .tab-items .ti a {
  background-color: #f2f2f2;
  color: #777;
  text-decoration: none;
  display: block;
  padding: 5px 10px;
}
.tab-container .tab-items .ti:hover a {
  color: #555;
  background-color: #f7f7f7;
}
.tab-container .tab-items .ti.tab-selected {
  border-bottom-color: #fff;
}
.tab-container .tab-items .ti.tab-selected a {
  color: #777;
  background-color: #fff;
}
.tab-container .tab-content {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 15px;
}

/* ==========================================================================
   Standard CSS for Basic Search UI
   baiken@vipasolutions.com, sbenes
   Updated July 25, 2014
   ========================================================================== */
/* Form Elements
   ========================================================================== */
/*$headings-margin-bottom: ($spacer / 2);*/
/*$headings-margin-bottom: ($spacer / 2);*/
/**
* Provide button look for MIWT MenuComponent
*/
.search .entity-actions .menu-component,
.search-wrapper .entity-actions .menu-component {
  margin-right: 25px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
  color: #fff;
  background-color: #259de2;
  border-color: #259de2;
  color: #fff;
}
@media (prefers-reduced-motion: reduce) {
  .search .entity-actions .menu-component,
.search-wrapper .entity-actions .menu-component {
    transition: none;
  }
}
.search .entity-actions .menu-component:focus, .search .entity-actions .menu-component.focus, .search .entity-actions .menu-component:active:focus, .search .entity-actions .menu-component:active.focus, .search .entity-actions .menu-component.active:focus, .search .entity-actions .menu-component.active.focus,
.search-wrapper .entity-actions .menu-component:focus,
.search-wrapper .entity-actions .menu-component.focus,
.search-wrapper .entity-actions .menu-component:active:focus,
.search-wrapper .entity-actions .menu-component:active.focus,
.search-wrapper .entity-actions .menu-component.active:focus,
.search-wrapper .entity-actions .menu-component.active.focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
.search .entity-actions .menu-component:hover, .search .entity-actions .menu-component:focus,
.search-wrapper .entity-actions .menu-component:hover,
.search-wrapper .entity-actions .menu-component:focus {
  text-decoration: none;
}
.search .entity-actions .menu-component.focus,
.search-wrapper .entity-actions .menu-component.focus {
  text-decoration: none;
}
.search .entity-actions .menu-component:active, .search .entity-actions .menu-component.active,
.search-wrapper .entity-actions .menu-component:active,
.search-wrapper .entity-actions .menu-component.active {
  background-image: none;
  outline: 0;
}
.search .entity-actions .menu-component.disabled, .search .entity-actions .menu-component:disabled,
.search-wrapper .entity-actions .menu-component.disabled,
.search-wrapper .entity-actions .menu-component:disabled {
  cursor: not-allowed;
  opacity: 0.65;
}
.search .entity-actions .menu-component:hover,
.search-wrapper .entity-actions .menu-component:hover {
  color: #fff;
  background-color: #1a88c6;
  border-color: #1980bb;
}
.search .entity-actions .menu-component:focus, .search .entity-actions .menu-component.focus,
.search-wrapper .entity-actions .menu-component:focus,
.search-wrapper .entity-actions .menu-component.focus {
  color: #fff;
  background-color: #1a88c6;
  border-color: #1980bb;
  box-shadow: 0 0 0 0.2rem rgba(70, 172, 230, 0.5);
}
.search .entity-actions .menu-component.disabled, .search .entity-actions .menu-component:disabled,
.search-wrapper .entity-actions .menu-component.disabled,
.search-wrapper .entity-actions .menu-component:disabled {
  color: #fff;
  background-color: #259de2;
  border-color: #259de2;
}
.search .entity-actions .menu-component:not(:disabled):not(.disabled):active, .search .entity-actions .menu-component:not(:disabled):not(.disabled).active, .show > .search .entity-actions .menu-component.dropdown-toggle,
.search-wrapper .entity-actions .menu-component:not(:disabled):not(.disabled):active,
.search-wrapper .entity-actions .menu-component:not(:disabled):not(.disabled).active,
.show > .search-wrapper .entity-actions .menu-component.dropdown-toggle {
  color: #fff;
  background-color: #1980bb;
  border-color: #1778b0;
}
.search .entity-actions .menu-component:not(:disabled):not(.disabled):active:focus, .search .entity-actions .menu-component:not(:disabled):not(.disabled).active:focus, .show > .search .entity-actions .menu-component.dropdown-toggle:focus,
.search-wrapper .entity-actions .menu-component:not(:disabled):not(.disabled):active:focus,
.search-wrapper .entity-actions .menu-component:not(:disabled):not(.disabled).active:focus,
.show > .search-wrapper .entity-actions .menu-component.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 172, 230, 0.5);
}

/* Search Bar
   ========================================================================== */
.search_bar,
.search-bar {
  margin: 10px 0;
}
.search_bar .constraint,
.search-bar .constraint {
  display: inline-block;
  white-space: nowrap;
  margin: 5px 5px 5px 0;
  padding: 5px 5px 5px 0;
  vertical-align: top;
}
.search_bar .constraint label,
.search-bar .constraint label {
  display: block;
  color: #343940;
  font-weight: 400;
  font-size: 0.85rem;
  margin-bottom: 0.15rem;
  text-transform: uppercase;
}
.search_bar .search-actions,
.search-bar .search-actions {
  display: inline-block;
  padding: 10px;
  margin-top: 22px;
}

.search_bar .actions {
  display: inline-block;
  padding: 10px;
  margin-top: 22px;
}

.constraint-input-conjunction {
  padding: 0 4px;
}

/* Actions
   ========================================================================== */
.search-wrapper .entity-actions,
.search .entity-actions {
  text-align: right;
  margin-top: -1.5rem;
}

.search_wrapper .result-actions,
.search-wrapper .result-actions {
  text-align: right;
  display: block;
  padding: 10px 0;
}

/* Results Table
   ========================================================================== */
.search .miwt-table,
.search-wrapper .miwt-table {
  width: 100%;
  margin: 20px 0;
}
.search .miwt-table tbody tr td,
.search-wrapper .miwt-table tbody tr td {
  background-color: #fff;
  text-align: center;
}
.search .miwt-table tbody tr :hover td,
.search-wrapper .miwt-table tbody tr :hover td {
  background-color: #feffdd;
}
.search .miwt-table tbody tr.inactive td,
.search-wrapper .miwt-table tbody tr.inactive td {
  background-color: #fab05f;
}

.search td.actions-col,
.search-wrapper td.actions-col {
  width: 1%;
  white-space: nowrap;
}

.search::after {
  display: block;
  clear: both;
  content: "";
}

select.bulk-actions,
select.bulk_actions {
  min-width: 8rem;
}

.search_bar input[type=text],
.search-bar input[type=text] {
  border: 1px solid #8492af;
  padding: 3px 5px 4px;
  font-size: 0.9rem;
}
.search_bar .range-constraint, .search_bar .range-selector,
.search-bar .range-constraint,
.search-bar .range-selector {
  white-space: normal;
}
.search_bar .range-constraint select, .search_bar .range-selector select,
.search-bar .range-constraint select,
.search-bar .range-selector select {
  min-width: 6rem;
}
.search_bar .range-constraint .constraint-label, .search_bar .range-selector .constraint-label,
.search-bar .range-constraint .constraint-label,
.search-bar .range-selector .constraint-label {
  display: block;
}
.search_bar .range-constraint .miwt-calendar input, .search_bar .range-selector .miwt-calendar input,
.search-bar .range-constraint .miwt-calendar input,
.search-bar .range-selector .miwt-calendar input {
  width: 10.5rem;
}
.search_bar .range-constraint .constraint-operator,
.search_bar .range-constraint .miwt-calendar,
.search_bar .range-constraint .constraint-input-conjunction, .search_bar .range-selector .constraint-operator,
.search_bar .range-selector .miwt-calendar,
.search_bar .range-selector .constraint-input-conjunction,
.search-bar .range-constraint .constraint-operator,
.search-bar .range-constraint .miwt-calendar,
.search-bar .range-constraint .constraint-input-conjunction,
.search-bar .range-selector .constraint-operator,
.search-bar .range-selector .miwt-calendar,
.search-bar .range-selector .constraint-input-conjunction {
  white-space: nowrap;
  display: inline-block;
  vertical-align: top;
}
.search_bar .range-constraint .constraint-input-conjunction, .search_bar .range-selector .constraint-input-conjunction,
.search-bar .range-constraint .constraint-input-conjunction,
.search-bar .range-selector .constraint-input-conjunction {
  padding: 6px 3px 0 0;
  margin-left: -8px;
  font-size: 0.8rem;
}
.search_bar .constraint.specialty_labels .select2-container,
.search-bar .constraint.specialty_labels .select2-container {
  width: auto !important;
  max-width: 20rem;
  min-width: 8rem;
}
.search_bar .actions.search-actions .btn,
.search-bar .actions.search-actions .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0;
}
.search_bar .constraint.company_customer .select2-container,
.search-bar .constraint.company_customer .select2-container {
  width: 100% !important;
}
.search_bar .constraint.state .select2-container,
.search-bar .constraint.state .select2-container {
  min-width: 70px !important;
}
.search_bar .constraint:first-child,
.search-bar .constraint:first-child {
  margin-left: 0;
  padding-left: 0;
}

.select2-container.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 25px;
}

.search-wrapper .actions .select2-container {
  min-width: 140px;
  text-align: left;
}

@media (max-width: 600px) {
  .search .entity-actions, .search-wrapper .entity-actions {
    position: absolute;
    top: 5px;
    right: 0;
    margin-top: 0;
  }
  #admin-template .search .entity-actions, #admin-template .search-wrapper .entity-actions {
    top: -20px;
  }
  .search-bar .constraints .constraint {
    width: 45%;
  }
  .search-bar .constraints .constraint input[type=text], .search-bar .constraints .constraint .select2-container {
    width: 100% !important;
  }
}
/*$headings-margin-bottom: ($spacer / 2);*/
div.pager-top {
  display: none;
}

.pager-bottom {
  display: inline-block;
  padding-left: 0;
  margin: 0;
  width: 100%;
  text-align: center;
}

.pages.page:first-child {
  margin-left: 0;
}
.pages .selected-page.page, .pages .selected-page.page:hover, .pages .selected-page.page:focus {
  z-index: 2;
  cursor: default;
  color: #fff;
  background-color: #259de2;
  border-color: #259de2;
}

.pages .page,
.mp-controls .control {
  position: relative;
  float: left;
  padding: 0.5rem 0.75rem;
  margin-left: 5px;
  line-height: 1.25;
  color: #fff;
  text-decoration: none;
  background-color: #385cad;
  border: 1px solid #385cad;
}
.pages .page:hover, .pages .page:focus,
.mp-controls .control:hover,
.mp-controls .control:focus {
  color: #fff;
  background-color: #259de2;
  border-color: #259de2;
}

.mp-controls.controls a {
  color: transparent;
  width: 40px;
  white-space: nowrap;
  overflow: hidden;
  padding: 0.35rem 0.75rem;
}
.mp-controls.controls a span.cl {
  height: 0;
  display: block;
}
.mp-controls.controls a:hover, .mp-controls.controls a:focus {
  color: transparent;
}
.mp-controls.controls a.last::after, .mp-controls.controls a.next::after {
  font-family: FontAwesome;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  font-size: 1.25rem;
}
.mp-controls.controls a.next::after {
  content: "\f105";
}
.mp-controls.controls a.last::after {
  content: "\f101";
}
.mp-controls.controls a.first::before, .mp-controls.controls a.prev::before {
  font-family: FontAwesome;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  font-size: 1.25rem;
}
.mp-controls.controls a.first::before {
  content: "\f100";
}
.mp-controls.controls a.prev::before {
  content: "\f104";
}

.pager .controls {
  display: inline-block;
  text-align: center;
  margin: 0 1rem;
}

/*$headings-margin-bottom: ($spacer / 2);*/
/* ==========================================================================
   Basic Styles for Property Editor/Viewer
   baiken@vipasolutions.com, sbenes
   Updated July 25, 2014
   ========================================================================== */
/* Form Elements
   ========================================================================== */
.miwt-procedure-card input,
.miwt-procedure-card textarea,
.prop-wrapper input,
.prop-wrapper textarea {
  border: 1px solid #8492af;
  padding: 2px;
  margin: 2px;
}
.miwt-procedure-card input:focus,
.miwt-procedure-card select:focus,
.miwt-procedure-card textarea:focus,
.prop-wrapper input:focus,
.prop-wrapper select:focus,
.prop-wrapper textarea:focus {
  background-color: #feffdd;
}
.miwt-procedure-card button,
.miwt-procedure-card input[type=submit],
.miwt-procedure-card input[type=reset],
.miwt-procedure-card .constraints a[data-features~=submit],
.prop-wrapper button,
.prop-wrapper input[type=submit],
.prop-wrapper input[type=reset],
.prop-wrapper .constraints a[data-features~=submit] {
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  box-shadow: 1px 1px 0 rgba(255, 255, 255, 0.8) inset, -1px -1px 0 rgba(0, 0, 0, 0.05) inset;
}
.container-view .miwt-procedure-card,
.container-view .prop-wrapper {
  max-width: none;
}

/* Content
	 ========================================================================== */
.miwt-procedure-card .prop-header-title,
.prop-wrapper .prop-header-title {
  font-size: 22px;
  font-weight: bold;
  margin: 1em 0;
}
.miwt-procedure-card .prop-group-title,
.prop-wrapper .prop-group-title {
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0;
}
.miwt-procedure-card span.ctb,
.prop-wrapper span.ctb {
  display: block;
}
.miwt-procedure-card .parameter > label,
.miwt-procedure-card .prop > label,
.prop-wrapper .parameter > label,
.prop-wrapper .prop > label {
  display: block;
  font-weight: 500;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.miwt-procedure-card .parameter > label.tbl,
.miwt-procedure-card .prop > label.tbl,
.prop-wrapper .parameter > label.tbl,
.prop-wrapper .prop > label.tbl {
  display: inline-block;
  padding-right: 4px;
}
.miwt-procedure-card .parameter > .val,
.miwt-procedure-card .prop > .val,
.prop-wrapper .parameter > .val,
.prop-wrapper .prop > .val {
  display: inline-block;
}
.miwt-procedure-card header .actions, .miwt-procedure-card footer .actions,
.prop-wrapper header .actions,
.prop-wrapper footer .actions {
  margin: 10px 0;
  text-align: right;
}
.miwt-procedure-card .actions.bottom,
.prop-wrapper .actions.bottom {
  text-align: left;
}
.miwt-procedure-card .parameter,
.miwt-procedure-card .prop,
.prop-wrapper .parameter,
.prop-wrapper .prop {
  margin-bottom: 1rem;
  padding: 5px;
}
.miwt-procedure-card .parameter > .actions .btn, .miwt-procedure-card .parameter .selected-link .btn,
.miwt-procedure-card .prop > .actions .btn,
.miwt-procedure-card .prop .selected-link .btn,
.prop-wrapper .parameter > .actions .btn,
.prop-wrapper .parameter .selected-link .btn,
.prop-wrapper .prop > .actions .btn,
.prop-wrapper .prop .selected-link .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1;
  border-radius: 0;
}
.miwt-procedure-card .parameter > .instructions,
.miwt-procedure-card .prop > .instructions,
.prop-wrapper .parameter > .instructions,
.prop-wrapper .prop > .instructions {
  display: block;
  font-style: italic;
  color: #666666;
  font-size: 0.9rem;
}
.miwt-procedure-card .prop-group,
.prop-wrapper .prop-group {
  margin-bottom: 0;
}
.miwt-procedure-card .validation-error input[type=text],
.prop-wrapper .validation-error input[type=text] {
  border: 1px solid red;
}

.prop-editor .user-entry-required > label:after {
  content: "*";
  color: #ff0000;
}

.prop-viewer,
.prop-editor {
  padding: 0.5rem;
}

.prop-viewer .actions.top {
  display: none;
}
.prop-viewer .prop {
  display: block;
}
.prop-viewer .prop label, .prop-viewer .prop span, .prop-viewer .prop div.address {
  display: inline-block;
  vertical-align: top;
}
.prop-viewer .prop label {
  margin-bottom: 0;
}
.prop-viewer .prop label::after {
  content: ":";
  margin-right: 8px;
}
.prop-viewer .prop.timezone > label {
  margin-top: 0;
}
.prop-viewer .prop.email > div {
  margin: 0;
  padding: 0;
}
.prop-viewer .prop > .address span {
  margin-right: 8px;
}

.prop-editor .prop input[type=text]:not([maxlength]),
.prop-editor .prop input[type=number]:not([maxlength]),
.prop-editor .prop input[type=email],
.prop-editor .prop input[type=url],
.prop-editor .prop input[type=tel],
.prop-editor .prop textarea {
  min-width: 25rem;
}
.prop-editor .prop .actions {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0;
}
.prop-editor .miwt-calendar input[type=text] {
  min-width: 8rem;
}

.user-picture.mode-view {
  max-width: 325px;
  box-sizing: content-box;
}
.user-picture.mode-view img {
  max-width: 325px;
}

.resource-picture.mode-view {
  max-width: 350px;
  box-sizing: content-box;
}

.picture-editor .actions.bottom {
  text-align: center;
}

.prop.picture img {
  border-radius: 100%;
  max-height: 200px;
}

/*$headings-margin-bottom: ($spacer / 2);*/
/*
	Component partial -	File chooser popup
*/
.file_chooser {
  width: 100%;
  /*
  .btn-image {
  	vertical-align: middle;
  }
  */
}
.file_chooser td {
  vertical-align: middle;
}
.file_chooser td.sibling_directories-con, .file_chooser td.cwd-con, .file_chooser td.pager-con, .file_chooser td.actions-con {
  vertical-align: top;
}
.file_chooser td.pager-con {
  height: 100%;
  width: 100%;
}
.file_chooser td.sibling_directories-con {
  min-width: 200px;
  max-width: 300px;
}
.file_chooser td.actions-con div.actions {
  margin: 5px 0;
}
.file_chooser td.actions-con label {
  width: 235px;
  display: inline-block;
}
.file_chooser td.actions-con .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0;
  margin-left: 3px;
}
.file_chooser .fse_file,
.file_chooser .fse_folder {
  text-decoration: none;
  color: #007bff;
}
.file_chooser .fse_icon_name .btn.add {
  margin-right: 3px;
}
.file_chooser .fse_icon {
  padding-right: 3px;
}
.file_chooser .element input.selector {
  display: none;
}
.file_chooser div.sibling_directories {
  padding-right: 10px;
}
.file_chooser div.cwd {
  font-size: 16px;
  margin-bottom: 10px;
}

/*$headings-margin-bottom: ($spacer / 2);*/
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.actions .btn, .procedure-navigation .btn {
  margin-right: 4px;
}

.actions .btn, .add-listing .btn, .procedure-navigation .btn {
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 0;
  box-shadow: none;
}
.actions .btn:first-child,
.actions .btn .btn-primary, .add-listing .btn:first-child,
.add-listing .btn .btn-primary, .procedure-navigation .btn:first-child,
.procedure-navigation .btn .btn-primary {
  color: #fff;
  background-color: #259de2;
  border-color: #259de2;
  color: #fff;
}
.actions .btn:first-child:hover,
.actions .btn .btn-primary:hover, .add-listing .btn:first-child:hover,
.add-listing .btn .btn-primary:hover, .procedure-navigation .btn:first-child:hover,
.procedure-navigation .btn .btn-primary:hover {
  color: #fff;
  background-color: #1a88c6;
  border-color: #1980bb;
}
.actions .btn:first-child:focus, .actions .btn:first-child.focus,
.actions .btn .btn-primary:focus,
.actions .btn .btn-primary.focus, .add-listing .btn:first-child:focus, .add-listing .btn:first-child.focus,
.add-listing .btn .btn-primary:focus,
.add-listing .btn .btn-primary.focus, .procedure-navigation .btn:first-child:focus, .procedure-navigation .btn:first-child.focus,
.procedure-navigation .btn .btn-primary:focus,
.procedure-navigation .btn .btn-primary.focus {
  color: #fff;
  background-color: #1a88c6;
  border-color: #1980bb;
  box-shadow: 0 0 0 0.2rem rgba(70, 172, 230, 0.5);
}
.actions .btn:first-child.disabled, .actions .btn:first-child:disabled,
.actions .btn .btn-primary.disabled,
.actions .btn .btn-primary:disabled, .add-listing .btn:first-child.disabled, .add-listing .btn:first-child:disabled,
.add-listing .btn .btn-primary.disabled,
.add-listing .btn .btn-primary:disabled, .procedure-navigation .btn:first-child.disabled, .procedure-navigation .btn:first-child:disabled,
.procedure-navigation .btn .btn-primary.disabled,
.procedure-navigation .btn .btn-primary:disabled {
  color: #fff;
  background-color: #259de2;
  border-color: #259de2;
}
.actions .btn:first-child:not(:disabled):not(.disabled):active, .actions .btn:first-child:not(:disabled):not(.disabled).active, .show > .actions .btn:first-child.dropdown-toggle,
.actions .btn .btn-primary:not(:disabled):not(.disabled):active,
.actions .btn .btn-primary:not(:disabled):not(.disabled).active,
.show > .actions .btn .btn-primary.dropdown-toggle, .add-listing .btn:first-child:not(:disabled):not(.disabled):active, .add-listing .btn:first-child:not(:disabled):not(.disabled).active, .show > .add-listing .btn:first-child.dropdown-toggle,
.add-listing .btn .btn-primary:not(:disabled):not(.disabled):active,
.add-listing .btn .btn-primary:not(:disabled):not(.disabled).active,
.show > .add-listing .btn .btn-primary.dropdown-toggle, .procedure-navigation .btn:first-child:not(:disabled):not(.disabled):active, .procedure-navigation .btn:first-child:not(:disabled):not(.disabled).active, .show > .procedure-navigation .btn:first-child.dropdown-toggle,
.procedure-navigation .btn .btn-primary:not(:disabled):not(.disabled):active,
.procedure-navigation .btn .btn-primary:not(:disabled):not(.disabled).active,
.show > .procedure-navigation .btn .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #1980bb;
  border-color: #1778b0;
}
.actions .btn:first-child:not(:disabled):not(.disabled):active:focus, .actions .btn:first-child:not(:disabled):not(.disabled).active:focus, .show > .actions .btn:first-child.dropdown-toggle:focus,
.actions .btn .btn-primary:not(:disabled):not(.disabled):active:focus,
.actions .btn .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .actions .btn .btn-primary.dropdown-toggle:focus, .add-listing .btn:first-child:not(:disabled):not(.disabled):active:focus, .add-listing .btn:first-child:not(:disabled):not(.disabled).active:focus, .show > .add-listing .btn:first-child.dropdown-toggle:focus,
.add-listing .btn .btn-primary:not(:disabled):not(.disabled):active:focus,
.add-listing .btn .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .add-listing .btn .btn-primary.dropdown-toggle:focus, .procedure-navigation .btn:first-child:not(:disabled):not(.disabled):active:focus, .procedure-navigation .btn:first-child:not(:disabled):not(.disabled).active:focus, .show > .procedure-navigation .btn:first-child.dropdown-toggle:focus,
.procedure-navigation .btn .btn-primary:not(:disabled):not(.disabled):active:focus,
.procedure-navigation .btn .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .procedure-navigation .btn .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 172, 230, 0.5);
}
.actions .btn:first-child:hover, .actions .btn:first-child:focus, .actions .btn:first-child:active,
.actions .btn .btn-primary:hover,
.actions .btn .btn-primary:focus,
.actions .btn .btn-primary:active, .add-listing .btn:first-child:hover, .add-listing .btn:first-child:focus, .add-listing .btn:first-child:active,
.add-listing .btn .btn-primary:hover,
.add-listing .btn .btn-primary:focus,
.add-listing .btn .btn-primary:active, .procedure-navigation .btn:first-child:hover, .procedure-navigation .btn:first-child:focus, .procedure-navigation .btn:first-child:active,
.procedure-navigation .btn .btn-primary:hover,
.procedure-navigation .btn .btn-primary:focus,
.procedure-navigation .btn .btn-primary:active {
  color: #fff;
}
.actions .btn:not(:first-child), .add-listing .btn:not(:first-child), .procedure-navigation .btn:not(:first-child) {
  color: #212529;
  background-color: #ffc629;
  border-color: #ffc629;
  color: #fff;
}
.actions .btn:not(:first-child):hover, .add-listing .btn:not(:first-child):hover, .procedure-navigation .btn:not(:first-child):hover {
  color: #212529;
  background-color: #ffbc03;
  border-color: #f5b400;
}
.actions .btn:not(:first-child):focus, .actions .btn:not(:first-child).focus, .add-listing .btn:not(:first-child):focus, .add-listing .btn:not(:first-child).focus, .procedure-navigation .btn:not(:first-child):focus, .procedure-navigation .btn:not(:first-child).focus {
  color: #212529;
  background-color: #ffbc03;
  border-color: #f5b400;
  box-shadow: 0 0 0 0.2rem rgba(222, 174, 41, 0.5);
}
.actions .btn:not(:first-child).disabled, .actions .btn:not(:first-child):disabled, .add-listing .btn:not(:first-child).disabled, .add-listing .btn:not(:first-child):disabled, .procedure-navigation .btn:not(:first-child).disabled, .procedure-navigation .btn:not(:first-child):disabled {
  color: #212529;
  background-color: #ffc629;
  border-color: #ffc629;
}
.actions .btn:not(:first-child):not(:disabled):not(.disabled):active, .actions .btn:not(:first-child):not(:disabled):not(.disabled).active, .show > .actions .btn:not(:first-child).dropdown-toggle, .add-listing .btn:not(:first-child):not(:disabled):not(.disabled):active, .add-listing .btn:not(:first-child):not(:disabled):not(.disabled).active, .show > .add-listing .btn:not(:first-child).dropdown-toggle, .procedure-navigation .btn:not(:first-child):not(:disabled):not(.disabled):active, .procedure-navigation .btn:not(:first-child):not(:disabled):not(.disabled).active, .show > .procedure-navigation .btn:not(:first-child).dropdown-toggle {
  color: #212529;
  background-color: #f5b400;
  border-color: #e8aa00;
}
.actions .btn:not(:first-child):not(:disabled):not(.disabled):active:focus, .actions .btn:not(:first-child):not(:disabled):not(.disabled).active:focus, .show > .actions .btn:not(:first-child).dropdown-toggle:focus, .add-listing .btn:not(:first-child):not(:disabled):not(.disabled):active:focus, .add-listing .btn:not(:first-child):not(:disabled):not(.disabled).active:focus, .show > .add-listing .btn:not(:first-child).dropdown-toggle:focus, .procedure-navigation .btn:not(:first-child):not(:disabled):not(.disabled):active:focus, .procedure-navigation .btn:not(:first-child):not(:disabled):not(.disabled).active:focus, .show > .procedure-navigation .btn:not(:first-child).dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 174, 41, 0.5);
}
.actions .btn:not(:first-child):hover, .actions .btn:not(:first-child):focus, .actions .btn:not(:first-child):active, .add-listing .btn:not(:first-child):hover, .add-listing .btn:not(:first-child):focus, .add-listing .btn:not(:first-child):active, .procedure-navigation .btn:not(:first-child):hover, .procedure-navigation .btn:not(:first-child):focus, .procedure-navigation .btn:not(:first-child):active {
  color: #fff;
}
.actions .btn.add, .add-listing .btn.add, .procedure-navigation .btn.add {
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0;
  height: auto;
}
.actions .btn.add::before, .add-listing .btn.add::before, .procedure-navigation .btn.add::before {
  content: "\f067";
  font-family: FontAwesome;
  padding-right: 5px;
  font-weight: 300;
  font-size: 85%;
  color: #fff;
}
.actions .btn.search, .add-listing .btn.search, .procedure-navigation .btn.search {
  color: #fff;
  background-color: #48b35f;
  border-color: #41a155;
  width: 40px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0 !important;
}
.actions .btn.search:hover, .add-listing .btn.search:hover, .procedure-navigation .btn.search:hover {
  color: #fff;
  background-color: #3d9851;
  border-color: #327c42;
}
.actions .btn.search:focus, .actions .btn.search.focus, .add-listing .btn.search:focus, .add-listing .btn.search.focus, .procedure-navigation .btn.search:focus, .procedure-navigation .btn.search.focus {
  color: #fff;
  background-color: #3d9851;
  border-color: #327c42;
  box-shadow: 0 0 0 0.2rem rgba(94, 175, 111, 0.5);
}
.actions .btn.search.disabled, .actions .btn.search:disabled, .add-listing .btn.search.disabled, .add-listing .btn.search:disabled, .procedure-navigation .btn.search.disabled, .procedure-navigation .btn.search:disabled {
  color: #fff;
  background-color: #48b35f;
  border-color: #41a155;
}
.actions .btn.search:not(:disabled):not(.disabled):active, .actions .btn.search:not(:disabled):not(.disabled).active, .show > .actions .btn.search.dropdown-toggle, .add-listing .btn.search:not(:disabled):not(.disabled):active, .add-listing .btn.search:not(:disabled):not(.disabled).active, .show > .add-listing .btn.search.dropdown-toggle, .procedure-navigation .btn.search:not(:disabled):not(.disabled):active, .procedure-navigation .btn.search:not(:disabled):not(.disabled).active, .show > .procedure-navigation .btn.search.dropdown-toggle {
  color: #fff;
  background-color: #398f4c;
  border-color: #2e733d;
}
.actions .btn.search:not(:disabled):not(.disabled):active:focus, .actions .btn.search:not(:disabled):not(.disabled).active:focus, .show > .actions .btn.search.dropdown-toggle:focus, .add-listing .btn.search:not(:disabled):not(.disabled):active:focus, .add-listing .btn.search:not(:disabled):not(.disabled).active:focus, .show > .add-listing .btn.search.dropdown-toggle:focus, .procedure-navigation .btn.search:not(:disabled):not(.disabled):active:focus, .procedure-navigation .btn.search:not(:disabled):not(.disabled).active:focus, .show > .procedure-navigation .btn.search.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(94, 175, 111, 0.5);
}
.actions .btn.search::before, .add-listing .btn.search::before, .procedure-navigation .btn.search::before {
  content: "\f002";
  font-family: FontAwesome;
  color: #fff;
  font-weight: 200;
  font-size: 1.1rem;
  padding-left: 3px;
}
.actions .btn.reset, .add-listing .btn.reset, .procedure-navigation .btn.reset {
  color: #fff;
  background-color: #8492af;
  border-color: #7584a5;
  width: 40px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0 !important;
}
.actions .btn.reset:hover, .add-listing .btn.reset:hover, .procedure-navigation .btn.reset:hover {
  color: #fff;
  background-color: #6d7da0;
  border-color: #5b6b8c;
}
.actions .btn.reset:focus, .actions .btn.reset.focus, .add-listing .btn.reset:focus, .add-listing .btn.reset.focus, .procedure-navigation .btn.reset:focus, .procedure-navigation .btn.reset.focus {
  color: #fff;
  background-color: #6d7da0;
  border-color: #5b6b8c;
  box-shadow: 0 0 0 0.2rem rgba(138, 150, 179, 0.5);
}
.actions .btn.reset.disabled, .actions .btn.reset:disabled, .add-listing .btn.reset.disabled, .add-listing .btn.reset:disabled, .procedure-navigation .btn.reset.disabled, .procedure-navigation .btn.reset:disabled {
  color: #fff;
  background-color: #8492af;
  border-color: #7584a5;
}
.actions .btn.reset:not(:disabled):not(.disabled):active, .actions .btn.reset:not(:disabled):not(.disabled).active, .show > .actions .btn.reset.dropdown-toggle, .add-listing .btn.reset:not(:disabled):not(.disabled):active, .add-listing .btn.reset:not(:disabled):not(.disabled).active, .show > .add-listing .btn.reset.dropdown-toggle, .procedure-navigation .btn.reset:not(:disabled):not(.disabled):active, .procedure-navigation .btn.reset:not(:disabled):not(.disabled).active, .show > .procedure-navigation .btn.reset.dropdown-toggle {
  color: #fff;
  background-color: #65779b;
  border-color: #566584;
}
.actions .btn.reset:not(:disabled):not(.disabled):active:focus, .actions .btn.reset:not(:disabled):not(.disabled).active:focus, .show > .actions .btn.reset.dropdown-toggle:focus, .add-listing .btn.reset:not(:disabled):not(.disabled):active:focus, .add-listing .btn.reset:not(:disabled):not(.disabled).active:focus, .show > .add-listing .btn.reset.dropdown-toggle:focus, .procedure-navigation .btn.reset:not(:disabled):not(.disabled):active:focus, .procedure-navigation .btn.reset:not(:disabled):not(.disabled).active:focus, .show > .procedure-navigation .btn.reset.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(138, 150, 179, 0.5);
}
.actions .btn.reset::before, .add-listing .btn.reset::before, .procedure-navigation .btn.reset::before {
  content: "\f00d";
  font-family: FontAwesome;
  color: #fff;
  font-weight: 200;
  font-size: 1.1rem;
  padding-left: 4px;
}
.actions .btn:hover, .add-listing .btn:hover, .procedure-navigation .btn:hover {
  text-decoration: none;
}

.csa-file .btn {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  background-color: #259de2;
  border-color: #259de2;
  color: #fff;
}
.csa-file .btn:hover {
  color: #fff;
  background-color: #1a88c6;
  border-color: #1980bb;
}
.csa-file .btn:focus, .csa-file .btn.focus {
  color: #fff;
  background-color: #1a88c6;
  border-color: #1980bb;
  box-shadow: 0 0 0 0.2rem rgba(70, 172, 230, 0.5);
}
.csa-file .btn.disabled, .csa-file .btn:disabled {
  color: #fff;
  background-color: #259de2;
  border-color: #259de2;
}
.csa-file .btn:not(:disabled):not(.disabled):active, .csa-file .btn:not(:disabled):not(.disabled).active, .show > .csa-file .btn.dropdown-toggle {
  color: #fff;
  background-color: #1980bb;
  border-color: #1778b0;
}
.csa-file .btn:not(:disabled):not(.disabled):active:focus, .csa-file .btn:not(:disabled):not(.disabled).active:focus, .show > .csa-file .btn.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 172, 230, 0.5);
}
.csa-file .btn:hover, .csa-file .btn:focus, .csa-file .btn:active {
  color: #fff;
}

.action-column .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1;
  border-radius: 0;
}

@media (max-width: 600px) {
  .actions .btn.add, .add-listing .btn.add {
    color: transparent;
    width: 35px;
    overflow: hidden;
  }
  .actions .btn.add:hover, .add-listing .btn.add:hover {
    color: transparent;
  }
  .actions .btn.add::before, .add-listing .btn.add::before {
    color: #fff !important;
  }
}
.breadcrumb_path {
  display: none;
}

@media (min-width: 1024px) {
  .breadcrumb_path {
    display: block;
  }
  .breadcrumb_path ol {
    padding: 0;
    margin: 0;
  }
  .breadcrumb_path li {
    display: inline-block;
    zoom: 1;
    *display: inline;
    margin-bottom: 15px;
    font-size: 0.8rem;
    color: #515B6B;
    text-transform: uppercase;
    font-family: "raleway", sans-serif;
    font-weight: 600;
  }
  .breadcrumb_path li a {
    color: #515B6B;
  }
  .breadcrumb_path li + li {
    margin-left: 7px;
  }
  .breadcrumb_path li + li:before {
    content: "\f105";
    font-family: FontAwesome;
    margin-right: 7px;
  }
  .breadcrumb_path li + li.current, .breadcrumb_path li + li a:hover {
    color: #000;
  }
}
/*$headings-margin-bottom: ($spacer / 2);*/
.user-viewer,
.customer-viewer,
.location-viewer,
.company-viewer,
.user-roles {
  padding: 1.5rem;
  background: #fff;
  border: 1px solid #8492af;
}

.user-viewer,
.customer-viewer,
.location-viewer,
.company-viewer,
.user-roles,
.user-mgt {
  width: 100%;
  max-width: 100%;
}
.user-viewer .actions.top,
.customer-viewer .actions.top,
.location-viewer .actions.top,
.company-viewer .actions.top,
.user-roles .actions.top,
.user-mgt .actions.top {
  display: block;
  margin-top: 0;
}
.user-viewer .prop,
.customer-viewer .prop,
.location-viewer .prop,
.company-viewer .prop,
.user-roles .prop,
.user-mgt .prop {
  display: block;
  width: 100%;
}
.user-viewer .prop label, .user-viewer .prop > span, .user-viewer .prop div.address,
.customer-viewer .prop label,
.customer-viewer .prop > span,
.customer-viewer .prop div.address,
.location-viewer .prop label,
.location-viewer .prop > span,
.location-viewer .prop div.address,
.company-viewer .prop label,
.company-viewer .prop > span,
.company-viewer .prop div.address,
.user-roles .prop label,
.user-roles .prop > span,
.user-roles .prop div.address,
.user-mgt .prop label,
.user-mgt .prop > span,
.user-mgt .prop div.address {
  display: block;
  vertical-align: top;
  width: 100%;
}
.user-viewer .prop label,
.customer-viewer .prop label,
.location-viewer .prop label,
.company-viewer .prop label,
.user-roles .prop label,
.user-mgt .prop label {
  margin-bottom: 0;
  text-align: left;
  font-weight: normal;
  color: #d6d9df;
}
.user-viewer .prop label::after,
.customer-viewer .prop label::after,
.location-viewer .prop label::after,
.company-viewer .prop label::after,
.user-roles .prop label::after,
.user-mgt .prop label::after {
  content: ":";
  margin-right: 15px;
}
.user-viewer .prop.timezone > label,
.customer-viewer .prop.timezone > label,
.location-viewer .prop.timezone > label,
.company-viewer .prop.timezone > label,
.user-roles .prop.timezone > label,
.user-mgt .prop.timezone > label {
  margin-top: 0;
}
.user-viewer .prop.email > div,
.customer-viewer .prop.email > div,
.location-viewer .prop.email > div,
.company-viewer .prop.email > div,
.user-roles .prop.email > div,
.user-mgt .prop.email > div {
  margin: 0;
  padding: 0;
}
.user-viewer .prop > .address span,
.customer-viewer .prop > .address span,
.location-viewer .prop > .address span,
.company-viewer .prop > .address span,
.user-roles .prop > .address span,
.user-mgt .prop > .address span {
  display: block;
  /*&.address_line::after {
                content: ',';
              }*/
}

.user-viewer .bottom.actions,
.customer-viewer .bottom.actions,
.location-viewer .bottom.actions,
.company-viewer .bottom.actions,
.user-roles .bottom.actions {
  display: none;
}

#client-manage-view #column1 .sub-nav {
  margin-top: 8rem;
}

.user-viewer .prop-body,
.customer-viewer .prop-body {
  display: flex;
  flex-flow: column nowrap;
  /*.prop {
          margin-bottom: 2rem;
        }*/
}
.user-viewer .prop.picture,
.customer-viewer .prop.picture {
  flex: 1 0 auto;
  text-align: center;
  position: relative;
}
.user-viewer .prop-group.contact,
.customer-viewer .prop-group.contact {
  flex: 1 0 auto;
  display: flex;
  flex-flow: row wrap;
  padding-left: 1rem;
  /*.name-editor > div{
  	flex: 1 1 100%;
  }*/
}
.user-viewer .prop-group.contact .name-editor, .user-viewer .prop-group.contact .prop-group,
.customer-viewer .prop-group.contact .name-editor,
.customer-viewer .prop-group.contact .prop-group {
  display: flex;
  flex-flow: row wrap;
  flex: 1 0 100%;
}
.user-viewer .prop-group.contact label,
.customer-viewer .prop-group.contact label {
  margin-bottom: 0.15rem;
}
.user-viewer .prop-group.contact .address,
.customer-viewer .prop-group.contact .address {
  flex-flow: column nowrap;
}

.user-roles {
  display: flex;
  flex-flow: row wrap;
}
.user-roles h1 {
  font-size: 1.5rem;
  flex: 1 0 100%;
  text-transform: none;
}
.user-roles > div {
  flex: 1 0 50%;
}
.user-roles .company-roles h2, .user-roles .location-roles h2 {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 1rem;
}
.user-roles span.ctb {
  display: block;
}
.user-roles span.ctb label {
  margin-left: 0.25rem;
}

div.window.dialog.set-operator-pin-dialog {
  width: 24rem !important;
  left: 50% !important;
  margin-left: -12rem !important;
}
div.window.dialog.set-operator-pin-dialog .prop-header-actions {
  display: none;
}
div.window.dialog.set-operator-pin-dialog .prop-body {
  max-width: 50%;
  margin: 0 auto;
}
div.window.dialog.set-operator-pin-dialog label {
  display: block;
  margin-bottom: 0;
}
div.window.dialog.set-operator-pin-dialog input[type=password] {
  width: 100%;
}
div.window.dialog.set-operator-pin-dialog .bottom.actions {
  padding: 15px 0;
}

@media (max-width: 600px) {
  .user-roles {
    flex-flow: column nowrap;
  }
  .user-roles > h1 {
    flex: 1 0 auto;
  }
  .user-roles > div {
    flex: 1 0 auto;
  }
  .customer-viewer .prop.picture, .user-viewer .prop.picture {
    flex: 1 0 auto;
  }
  .customer-viewer .prop-body, .user-viewer .prop-body {
    flex-flow: column nowrap;
  }
  .customer-viewer .prop-body > div:not(.locations), .customer-viewer .prop-body > div:not(.company-customer), .user-viewer .prop-body > div:not(.locations), .user-viewer .prop-body > div:not(.company-customer) {
    flex-flow: column nowrap;
  }
  .customer-viewer .prop-body .prop.locations, .customer-viewer .prop-body .prop.company-customer, .user-viewer .prop-body .prop.locations, .user-viewer .prop-body .prop.company-customer {
    margin-left: 0;
  }
  .customer-viewer .prop-group.contact, .user-viewer .prop-group.contact {
    padding-left: 0;
    flex: 1 0 auto;
    flex-flow: column nowrap;
  }
  .customer-viewer .prop-group.contact > .prop, .user-viewer .prop-group.contact > .prop {
    flex: 1 0 auto;
  }
  .customer-viewer .prop-group.contact .name-editor, .customer-viewer .prop-group.contact .prop-group, .customer-viewer .prop-group.contact .name-editor > div,
.customer-viewer .prop-group.contact .address > div, .customer-viewer .prop-group.contact > .phone, .user-viewer .prop-group.contact .name-editor, .user-viewer .prop-group.contact .prop-group, .user-viewer .prop-group.contact .name-editor > div,
.user-viewer .prop-group.contact .address > div, .user-viewer .prop-group.contact > .phone {
    flex: 1 0 auto;
  }
  .customer-viewer .prop-group.contact .prop-group.email-address span.val, .user-viewer .prop-group.contact .prop-group.email-address span.val {
    word-break: break-all;
    word-break: break-word;
  }
  .customer-viewer .prop-group.contact .address, .user-viewer .prop-group.contact .address {
    flex-flow: column nowrap;
  }
  .customer-viewer .prop-group.contact .address > div, .user-viewer .prop-group.contact .address > div {
    flex: 1 0 auto;
  }
}
@media (min-width: 768px) {
  .user-viewer .prop label, .user-viewer .prop > span.val, .user-viewer .prop > span.localizable, .user-viewer .prop > span.ctb, .user-viewer .prop div.address,
.customer-viewer .prop label,
.customer-viewer .prop > span.val,
.customer-viewer .prop > span.localizable,
.customer-viewer .prop > span.ctb,
.customer-viewer .prop div.address,
.location-viewer .prop label,
.location-viewer .prop > span.val,
.location-viewer .prop > span.localizable,
.location-viewer .prop > span.ctb,
.location-viewer .prop div.address,
.company-viewer .prop label,
.company-viewer .prop > span.val,
.company-viewer .prop > span.localizable,
.company-viewer .prop > span.ctb,
.company-viewer .prop div.address,
.user-roles .prop label,
.user-roles .prop > span.val,
.user-roles .prop > span.localizable,
.user-roles .prop > span.ctb,
.user-roles .prop div.address {
    display: inline-block;
    vertical-align: top;
    width: 50%;
  }
  .user-viewer .prop label,
.customer-viewer .prop label,
.location-viewer .prop label,
.company-viewer .prop label,
.user-roles .prop label {
    text-align: right;
  }
}
.user-edit,
.customer-editor,
.location-add,
.location-edit,
.customer-add,
.user-editor,
.company-edit,
.company-add,
.edit-positions,
.add-positions {
  padding: 2rem;
  background: #fff;
  border: 1px solid #8492af;
}

@media (max-width: 768px) {
  .top-menu > li > .fa,
.top-menu > li.dropdown a.dropdown-toggle {
    font-size: 75%;
  }
  .img-responsive {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .sidebar-toggle-box .logo {
    display: none;
  }
  .top-menu {
    margin-top: 0;
  }
  .top-menu li.link {
    margin-right: 0;
  }
  .top-menu li.link .fa:before {
    font-size: 1rem;
  }
  .top-menu .dropdown .welcome {
    display: none;
  }
}
/* HORIZONTAL / HORIZ. PERSISTENT CSS --------------- */
ul.menubeanh,
ul.menubeanh-persistent {
  list-style: none;
  position: relative;
  overflow: visible;
}

ul.menubeanh,
ul.menubeanh ul,
ul.menubeanh-persistent,
ul.menubeanh-persistent ul {
  padding: 0;
  margin: 0;
  list-style: none;
  /* background-color: #fff;  IE Requires a background color. */
}

ul.menubeanh li,
ul.menubeanh-persistent li {
  float: left;
  position: relative;
  list-style: none;
  /*
  	width: 8em;
  overflow: visible;
  */
}

ul.menubeanh li ul,
ul.menubeanh-persistent li ul {
  display: none;
  position: absolute;
  top: 1.2em; /* IE */
  left: 0; /* IE */ /* background-color: #fff;  IE Requires a background color. */
}

ul.menubeanh > li > ul,
ul.menubeanh-persistent > li > ul {
  top: auto; /* Non-IE */
  left: auto; /* Non-IE */
}

/* (sub-)submenus */
ul.menubeanh ul,
ul.menubeanh ul ul,
ul.menubeanh ul ul ul,
ul.menubeanh-persistent ul,
ul.menubeanh-persistent ul ul,
ul.menubeanh-persistent ul ul ul {
  position: absolute;
  top: 0;
  left: 95%;
}

/* Interactive lines: show/hide menus */
ul.menubeanh li.over ul ul,
ul.menubeanh li.over ul ul ul,
ul.menubeanh-persistent li.over ul ul,
ul.menubeanh-persistent li.over ul ul ul {
  display: none;
}

ul.menubeanh li.over ul,
ul.menubeanh ul li.over ul,
ul.menubeanh ul ul li.over ul,
ul.menubeanh-persistent li.over ul,
ul.menubeanh-persistent ul li.over ul,
ul.menubeanh-persistent ul ul li.over ul {
  display: block;
}

/* VERTICAL CSS --------------- */
ul.menubeanv,
ul.menubeanv-persistent,
ul.menubeanv ul,
ul.menubeanv-persistent, ul {
  /* background-color: #fff;  IE requires a background color. */
}

ul.menubeanv li,
ul.menubeanv-persistent li {
  position: relative;
  /* background-color: #fff;  IE requires a background color. */
}

ul.menubeanv li ul {
  display: none;
  position: absolute;
  top: 2px; /* IE */
  left: 6em; /* IE */
  /* background-color: #fff;  IE requires a background color. */
}

ul.menubeanv li:hover ul,
ul.menubeanv li.over ul {
  display: block;
}

ul.menubeanv-persistent li.mi-parent {
  cursor: pointer;
}

ul.menubeanv-persistent li ul {
  display: none;
}

ul.menubeanv-persistent li.over ul {
  display: block;
}

/**
 * Mixin to build out the calendar theme
 * Created by djacobi on 6/12/17.
 */
/*$headings-margin-bottom: ($spacer / 2);*/
div.calendar {
  position: relative;
  border: none;
  font-size: 13px;
  color: #343940;
  cursor: default;
  background: #8f8f8f;
  font-family: Arial, sans-serif;
  /* Header part -- contains navigation buttons and day names. */
  /* The body part -- contains all the days in month. */
  /* The footer part -- status bar and "Close" button */
  /* Combo boxes (menus that display months/years for direct selection) */
  /* ----- TIME */
}
div.calendar table {
  border: none;
}
div.calendar .button { /* "<<", "<", ">", ">>" buttons have this class */
  text-align: center; /* They are the navigation buttons */
  padding: 4px; /* Make the buttons seem like they're pressing */
  cursor: pointer;
  color: #484848;
}
div.calendar .nav {
  background: #5ec0e9;
  color: #1679a2;
  position: relative;
}
div.calendar .nav::after {
  content: "";
  display: block;
  border-top: 4px solid #1679a2;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  position: absolute;
  right: 4px;
  bottom: 4px;
}
div.calendar thead .title { /* This holds the current "month, year" */
  font-weight: bold; /* Pressing it will take you to the current date */
  text-align: center;
  background: darkgray;
  color: #616161;
  padding: 4px;
}
div.calendar thead .headrow { /* Row <TR> containing navigation buttons */
  background: #5ec0e9;
  color: #343940;
}
div.calendar thead .headrow .hilite {
  background: #28abe2;
  color: #0f516d;
}
div.calendar thead .headrow .button {
  color: #1679a2;
}
div.calendar thead .daynames { /* Row <TR> containing the day names */
  background: #28abe2;
}
div.calendar thead .daynames .hilite {
  background: #5ec0e9;
  color: #1679a2;
}
div.calendar thead .name { /* Cells <TD> containing the day names */
  padding: 4px;
  text-align: center;
  color: #fff;
}
div.calendar thead .weekend { /* How a weekend day name shows in header */
  color: #0f516d;
}
div.calendar thead .active { /* Active (pressed) buttons in header */
  background-color: #1886b4;
  color: #4cb9e7;
}
div.calendar tbody .rowhilite td {
  background: darkgray;
}
div.calendar tbody .rowhilite td.wn {
  background: #28abe2;
}
div.calendar tbody .rowhilite td.weekend {
  color: #484848;
}
div.calendar tbody td {
  cursor: pointer;
}
div.calendar tbody td.day { /* Cells <TD> containing month days dates */
  width: 32px;
  color: #343940;
  text-align: center;
  padding: 4px;
}
div.calendar tbody td.day.othermonth {
  font-size: 80%;
  background-color: darkgray;
  color: #616161;
}
div.calendar tbody td.day.othermonth.oweekend {
  color: #616161;
}
div.calendar tbody td.hilite { /* Hovered cells <TD> */
  background: #c2c2c2;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.65);
  color: #7b7b7b;
}
div.calendar tbody td.active { /* Active (pressed) cells <TD> */
  background: #7b7b7b;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
}
div.calendar tbody td.selected, div.calendar tbody td.selected.weekend { /* Cell showing today date */
  font-weight: bold;
  background: #28abe2;
  color: #0f516d;
}
div.calendar tbody td.wn { /* Week numbers */
  padding: 2px 3px 2px 2px;
  background: #28abe2;
  color: #fff;
}
div.calendar tbody td.weekend { /* Cells showing weekend days */
  color: #484848;
}
div.calendar tbody td.weekend.hilite {
  color: #7b7b7b;
}
div.calendar tbody td.today {
  font-weight: bold;
  background: #5ec0e9;
  color: #1679a2;
}
div.calendar tbody .disabled {
  color: #999;
}
div.calendar tbody .emptycell { /* Empty cells (the best is to hide them) */
  visibility: hidden;
}
div.calendar tbody .emptyrow { /* Empty row (some months need less than 6 rows) */
  display: none;
}
div.calendar tfoot .footrow { /* The <TR> in footer (only one right now) */
  text-align: center;
  background: #5ec0e9;
  color: #1679a2;
}
div.calendar tfoot .ttip { /* Tooltip (status bar) cell <TD> */
  padding: 2px;
  background: #5ec0e9;
  color: #1679a2;
}
div.calendar tfoot .hilite { /* Hover style for buttons in footer */
  background: #28abe2;
}
div.calendar tfoot .active { /* Active (pressed) style for buttons in footer */
  background: #1b94c6;
}
div.calendar .combo {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 4em;
  cursor: default;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.45);
  background: #5ec0e9;
  color: #343940;
  font-size: 11px;
  z-index: 100;
}
div.calendar .combo .label,
div.calendar .combo .label-IEfix {
  text-align: center;
  padding: 4px;
  border-radius: 0;
  font-size: 100%;
  line-height: 1.25;
  width: 100%;
}
div.calendar .combo .label-IEfix {
  width: 4em;
}
div.calendar .combo .hilite {
  background: #28abe2;
}
div.calendar .combo .active {
  background: #28abe2;
  font-weight: bold;
}
div.calendar td.time {
  padding: 4px;
  text-align: center;
  background-color: #5ec0e9;
}
div.calendar td.time .hour,
div.calendar td.time .minute,
div.calendar td.time .ampm {
  padding: 0 3px 0 4px;
  font-weight: 600;
  color: #343940;
}
div.calendar td.time .ampm {
  text-align: center;
}
div.calendar td.time .colon {
  padding: 0px 2px 0px 3px;
  font-weight: 600;
}
div.calendar td.time span.hilite {
  background-color: #28abe2;
  color: #343940;
}
div.calendar td.time span.active {
  background-color: #8f8f8f;
  color: #343940;
}

.stop-the-world-wrapper {
  display: block !important;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
  background-color: red;
  padding: 0.25rem 1rem;
  color: yellow;
}
.stop-the-world-wrapper.empty {
  display: none !important;
}
.stop-the-world-wrapper .stop-the-world-wrapper-heading {
  font-weight: 600;
  font-size: 22px;
  float: right;
}
.stop-the-world-wrapper .stop-the-world-recheck {
  font-size: 14px;
  padding: 0.25rem 0.5rem;
}
.stop-the-world-wrapper .stop-the-world-recheck .recheck-msg {
  padding-left: 0.5rem;
}
.stop-the-world-wrapper .stop-the-world {
  display: block;
  padding: 0.5rem;
  border-bottom: yellow 1px solid;
}
.stop-the-world-wrapper .stop-the-world .dismiss-stw {
  float: right;
  background: transparent;
  border: none;
  font-size: 17px;
}
.stop-the-world-wrapper .stop-the-world .heading {
  display: block;
  font-weight: 600;
  font-size: 22px;
}
.stop-the-world-wrapper .stop-the-world .heading:after {
  content: ": ";
}
.stop-the-world-wrapper .stop-the-world .content {
  display: block;
  padding-left: 10px;
  font-size: 20px;
}
.stop-the-world-wrapper .stop-the-world .content .stw-error {
  display: block;
}

.copyable {
  cursor: url(/_design/default/icons/cursor-copy.png) 2 2, copy;
  -webkit-user-select: all;
     -moz-user-select: all;
          user-select: all;
}

.copyable-alert-box {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 10000;
}

.copyable-alert-box:empty {
  display: none;
}

.alert-dismissible {
  word-break: break-all;
}
.alert-dismissible .close {
  cursor: pointer;
  background: transparent;
  border: none;
  line-height: 1;
  padding: 0 15px;
  height: 100%;
  font-size: 0;
}
.alert-dismissible .close:after {
  font-family: FontAwesome;
  content: "\f00d";
  font-size: 13px;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.125rem;
}

/*$headings-margin-bottom: ($spacer / 2);*/
body:not(.context-release) .logo-con {
  position: relative;
}
body:not(.context-release) .logo-con::after {
  position: absolute;
  display: block;
  font-weight: 900;
  left: 38px;
  top: 15px;
  background-color: #385cad;
  color: #fff;
  padding: 0 6px;
}

body.context-development .logo-con::after {
  content: "DEV";
}
body.context-development img.logo {
  filter: hue-rotate(45deg);
}

body.context-qa .logo-con::after {
  content: "QA";
}
body.context-qa img.logo {
  filter: grayscale(100%);
}

body {
  background: #e7e9ee;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6d6e71;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
body div.page {
  display: flex;
  flex-flow: column nowrap;
}

#e-content {
  flex: 1 0 auto;
  min-height: calc(100vh - 90px);
  padding-bottom: 3rem;
}

a {
  color: #385cad;
}

#e-COLUMN-0 {
  padding: 2.5rem 1.25rem 0;
  display: flex;
  max-width: 80vw;
  margin: 0 auto;
}

.enclosing #column1 {
  float: none;
  flex: 1;
}
.enclosing #column2 {
  flex: 4;
  float: none;
}

#column1 .sub-nav {
  margin-top: 0;
  margin-bottom: 1rem;
}
#column1 .sub-nav .element {
  text-align: center;
  border-bottom: 1px solid #8492af;
}
#column1 .sub-nav .element a {
  padding: 0.5rem 0.75rem 0.5rem 0;
  font-family: "raleway", sans-serif;
  font-weight: 400;
  color: #6d6e71;
  text-transform: uppercase;
  display: block;
  border-bottom: 2px solid transparent;
}
#column1 .sub-nav .element a.mi-active, #column1 .sub-nav .element a:hover {
  font-weight: 600;
  border-bottom: 2px solid #ffc629;
  text-decoration: none;
}

#two_column_template #e-COLUMN-0 {
  display: flex;
  flex-flow: row nowrap;
}
#two_column_template #column1,
#two_column_template #column2 {
  flex: 1 0 50%;
  box-sizing: border-box;
}
#two_column_template #column1 {
  padding-right: 1.5rem;
}
#two_column_template #column2 {
  padding-left: 1.5rem;
}

.text.back-link {
  float: right;
  margin-top: 10px;
}
.text.back-link p {
  margin: 0;
}
.text.back-link a {
  color: #385cad;
  font-size: 0.875rem;
  text-decoration: none;
  display: block;
  width: 15px;
  overflow: hidden;
  white-space: nowrap;
}
.text.back-link a::before {
  content: "\f112";
  padding-right: 5px;
  font-family: FontAwesome;
  text-decoration: none;
}
.text.back-link a:hover {
  color: #8492af;
}

h1, h2, h3 {
  font-family: "Josefin Sans", sans-serif;
  text-transform: uppercase;
  color: #385cad;
  font-weight: 700;
}

h1, span.title, div.title, label.wizard_title {
  font-size: 2rem;
  line-height: 1.5;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
  color: #385cad;
  text-transform: uppercase;
}

h2, .h2 {
  font-size: 1.85rem;
  text-transform: none;
}

span.step_title {
  font-size: 1.5rem;
}

.update-password-dialog {
  width: 40rem !important;
}
.update-password-dialog .windowcontent {
  text-align: center;
}

section > h1 {
  margin: 10px 0;
  font-size: 2rem;
}
section section > h1 {
  font-size: 1.75rem;
  margin: 10px 0;
}

img {
  max-width: 100%;
  height: auto;
}

.miwt-calendar button.open,
.miwt-calendar button.clear {
  border: none;
  background: transparent;
  color: transparent;
  box-shadow: none;
}
.miwt-calendar button.open:before,
.miwt-calendar button.clear:before {
  visibility: visible;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f073";
  color: #8492af;
}
.miwt-calendar button.open:hover,
.miwt-calendar button.clear:hover {
  color: transparent;
  background: transparent;
}
.miwt-calendar button.open:hover::before,
.miwt-calendar button.clear:hover::before {
  color: #343940;
}
.miwt-calendar button.clear {
  padding-right: 0;
}
.miwt-calendar button.clear:before {
  content: "\f00d";
}

.select2-container.select2-container--default .select2-selection {
  border: 1px solid #8492af !important;
  border-radius: 0 !important;
  font-size: 0.9rem;
}
.select2-container.select2-container--default .select2-selection--single {
  height: 30px !important;
}
.select2-container.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 29px;
}
.select2-container.select2-container--default .select2-selection--multiple {
  height: 30px !important;
  min-height: auto;
  font-size: 12px;
}
.select2-container.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  line-height: 20px;
}
.select2-container.select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin-right: 4px;
  margin-top: 4px;
  padding: 0 4px;
}

.miwt-table > thead > tr > th {
  background: #385cad;
  text-transform: none;
  border-right: 1px solid #d6d9df;
}
.miwt-table > thead > tr > th:last-child {
  border-right: none;
}

.miwt-table > tbody > tr > td {
  border: 1px solid #e1e2e5;
  color: #6d6e71;
}

/* ---- THIS WILL EVENTUALLY GET REMOVED/CHANGED */
#footer {
  display: block;
  min-height: 4.625rem;
  height: auto;
  margin-top: 0;
  background-color: #d6d9df;
  color: #8492af;
  width: 100%;
}
#footer .text {
  padding: 1.5rem 0;
  max-width: 70rem;
  margin: 0 auto;
  text-align: center;
}
#footer .text p {
  margin: 0;
  font-size: 0.75rem;
}

@media (max-width: 768px) {
  .sidebar-open #e-COLUMN-0 {
    opacity: 0.25;
  }
}
@media (min-width: 768px) {
  #e-COLUMN-0 {
    flex-flow: row wrap;
  }
  h1, span.title {
    padding-right: 0;
  }
  #column1 .sub-nav {
    margin-top: 4.5rem;
  }
  #column1 .sub-nav .element {
    text-align: right;
  }
  #admin-template #column1 .sub-nav {
    margin-top: 2.5rem;
  }
  .text.back-link {
    margin-top: 1.55rem;
  }
  .text.back-link a {
    color: #385cad;
    font-size: 0.875rem;
    text-decoration: none;
    display: block;
    width: auto;
    overflow: hidden;
    white-space: nowrap;
  }
  .text.back-link a::after {
    content: "";
    height: 1px;
    background: #385cad;
    display: block;
    width: 89%;
    margin-top: -5px;
    margin-left: 11%;
  }
  .text.back-link a:hover {
    color: #8492af;
  }
}
.mail-class-constraint {
  min-width: 8ch;
}

tr.validation-error td:first-child {
  border-left: 3px solid #d93636;
}